/* #ff7200 */

html, body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
#root, #root > div, #root > div > div, main {
    height: 100%;
}
body {
    background: #ddd;
}

.wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#upload {
    height: 0;
    left: -10000px;
    overflow: hidden;
    position: absolute;
}

label {
    border: none;
    border-radius: 50px;
    width: 81px;
    height: 81px;
    display: block;
    cursor: pointer;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
    position: relative;
    /*box-shadow: 10px 10px 50px -2px rgba(0,0,0,0.23);
    background: #ff7200 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCxUWGSgxUVi1AAABPElEQVR42u3csQ3DMBDAQNlIH4/g/afyCp5AaZ0FcgJCdl89QUBQJY2BmMd8ciiPXS1ehQJoAU0BtICmAFpAUwAtoCmAFtAUQAtoCqAFNAXQApoCaAFNAbSApgBaQFMALaApgBbQFEALaAqgBTQF0AKaAmgBTQG0gKYAWkBTAC2gKYAW0BRAC2gKoAU0BdACmgJoAU0BtICmAFpAUwAtoCmAFtC82Kvd9/c0kcY21eZF+PsjUAAtoCmAFtBs8Bq8HtM5bp3ix/SHyCIUQAtoCqAFNAXQApoCaAFNAbSApgBaQFMALaApgBbQFEALaAqgBTQF0AKaAmgBTQG0gKYAWkBTAC2gKYAW0BRAC2gKoAU0BdACmgJoAU0BtICmAFpAUwAtoCmAFtAUQAtoCqAFNAXQApoCaAFNAbSA5gPl3CRaUkaZ0QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0yMVQyMjoyNTo0MCswMTowMAhqqXsAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMjFUMjI6MjU6NDArMDE6MDB5NxHHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==') no-repeat center center;
    background-size: 30px;*/
    background: transparent url('../../assets/add.svg') no-repeat center center;
    background-size: 100%;
}

.reset-button, .def-button {
    font-size:17px;
    font-weight: 700;
    color: #ffffff;
    background-color: #ee721e;
    height: 3em;
    width: 14em;
    border-radius: 1em;
    border:0 !important;
    opacity: 0;
    visibility: hidden;
    -webkit-appearance: none !important;
    box-shadow: none !important;
}

.def-button {
    opacity: 1;
    visibility: visible;
}

.upload-button {
    margin: 27px auto 15px;
    color: #fff;
    position: relative;
    letter-spacing: 0.1em;
    border: none !important;
    transition: .2s .2s width, .2s .2s height, .4s .2s color, .6s border, .6s background, .2s box-shadow;
    outline: none;
    cursor: pointer;
    width: 81px;
    height: 81px;
    border-radius: 50%;
    background: transparent url('../../assets/upload.svg') no-repeat center center;
}
.upload-button + span {
    display: block;
    margin: 0 auto;
    color: #1d1d1b;
    font-weight: 700;
}
.upload-button:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #ee721e;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .3s opacity;
    animation: spin 1s linear infinite;
}
.upload-button:before {
    content: attr(data-progress) "%";
    font-size: 1em;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: #ee721e;
    opacity: 0;
    transition: 0s;
}
.upload-button:hover {
    background: #ee721e;
    border-color: #ee721e;
}
.upload-button:active {
    box-shadow: 0 0 0 5px #ffd78e;
}
.upload-button:disabled {
    color: transparent;
    width: 5em;
    height: 5em;
    border: 4px solid #ffd78e;
    background: transparent;
    cursor: default;
    transition: .3s .2s width, .3s .2s height, .2s color, .3s .2s border, .3s .2s background;
}
.upload-button:disabled + span {
    font-weight: 400;
    font-style: italic;
}
.upload-button:disabled:after {
    opacity: 1;
    transition: .6s .3s opacity;
}
.upload-button:disabled:before {
    opacity: 1;
    transition: .6s .3s opacity;
}
.upload-button:disabled.completed {
    background: transparent url('../../assets/upload_success.svg') no-repeat center center;

}

.upload-button:disabled.completed:after, .upload-button:disabled.completed:before, .upload-button:disabled.completed + span  {
    opacity: 0;
    transition: .2s;
}

.is-completed .image-container {
    transition: opacity .35s ease;
    opacity: 0;
}
.is-completed textarea {
    transition: opacity .35s ease .2s;
    opacity: 0;
}
.is-completed .text-container {
    transform: translateY(-50%);
    transition: transform .6s ease .4s;
}
.is-completed .reset-button {
    opacity: 1;
    visibility: visible;
    transition: opacity .4s ease .5s;
}

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.image-container {

    max-height: 200px;
    text-align: center;
    margin-right: 28px;
}
.image-container img {
    max-width: 100%;
    max-height: 100%;
}
.desc-container, .edit-container {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -5%);
    background: #ddd;
    box-shadow: 10px 10px 50px -2px rgba(0,0,0,0.23);
    border-radius: 3px;
    padding: 45px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: transform .45s ease, opacity .45s ease, visibility 0s ease .45s;
}
.desc-container.is-visible,
.edit-container.is-visible {
    transform: translate(0,0);
    visibility: visible;
    z-index: 3;
    opacity: 1;
    transition: transform .45s ease, opacity .45s ease;
}

.edit-container.is-visible {
    z-index: 4;
}

.text-container {
    width: 50%;
    text-align: center;
    padding: 20px 35px;
}
@media all and (max-width: 720px) {
    .text-container, .image-container {
        width: 100%;
    }
    .image-container {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 27px;
    }
}

textarea {
    width: 100%;
    border: none;
    background: #efefef;
    font-size: 17px;
    color: #6d6d6d;
    height: 100px;
    padding: 10px 15px;
    outline: none !important;
    -webkit-appearance: none;
}
textarea:focus {

}

.main-logo {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 17px;
    left: 0;
}

@media all and (orientation: portrait) {
    .main-logo {
        top: 30px;
    }
}
.main-logo img {
    max-width: 196px;
}

.footer-logo {
    position: absolute;
    z-index: 1;
    max-width: 139px;
    height: auto;
    bottom: 17px;
    left: 17px;
}

#preview {
    max-height: 300px;
}

.cropper-bg {
    background-repeat: repeat;
}

.edit-container > div {
    width: 100%;
}

.cropper-container {
    width: 300px
}

.edit-container .image-container {
    width: 100%;
    height: 0 !important;
    padding-bottom: 60%;
}

.edit-container img {
    display: block;
    width: auto;
    height: 100%;
}

.cropper-point,
.cropper-line {
    background-color: #ee721e;
}

.cropper-view-box {
    outline-color: rgba(238, 114, 30, 0.7);
}

.edit-container {
    text-align: center;
}

.edit-title {
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    text-transform: uppercase;
}
