/* #ff7200 */

html, body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
#root, #root > div, #root > div > div, main {
    height: 100%;
}
body {
    background: #ddd;
}

.wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#upload {
    height: 0;
    left: -10000px;
    overflow: hidden;
    position: absolute;
}

label {
    border: none;
    border-radius: 50px;
    width: 81px;
    height: 81px;
    display: block;
    cursor: pointer;
    transition: .3s all ease;
    position: relative;
    /*box-shadow: 10px 10px 50px -2px rgba(0,0,0,0.23);
    background: #ff7200 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCxUWGSgxUVi1AAABPElEQVR42u3csQ3DMBDAQNlIH4/g/afyCp5AaZ0FcgJCdl89QUBQJY2BmMd8ciiPXS1ehQJoAU0BtICmAFpAUwAtoCmAFtAUQAtoCqAFNAXQApoCaAFNAbSApgBaQFMALaApgBbQFEALaAqgBTQF0AKaAmgBTQG0gKYAWkBTAC2gKYAW0BRAC2gKoAU0BdACmgJoAU0BtICmAFpAUwAtoCmAFtC82Kvd9/c0kcY21eZF+PsjUAAtoCmAFtBs8Bq8HtM5bp3ix/SHyCIUQAtoCqAFNAXQApoCaAFNAbSApgBaQFMALaApgBbQFEALaAqgBTQF0AKaAmgBTQG0gKYAWkBTAC2gKYAW0BRAC2gKoAU0BdACmgJoAU0BtICmAFpAUwAtoCmAFtAUQAtoCqAFNAXQApoCaAFNAbSA5gPl3CRaUkaZ0QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0yMVQyMjoyNTo0MCswMTowMAhqqXsAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMjFUMjI6MjU6NDArMDE6MDB5NxHHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==') no-repeat center center;
    background-size: 30px;*/
    background: transparent url(/static/media/add.d184081a.svg) no-repeat center center;
    background-size: 100%;
}

.reset-button, .def-button {
    font-size:17px;
    font-weight: 700;
    color: #ffffff;
    background-color: #ee721e;
    height: 3em;
    width: 14em;
    border-radius: 1em;
    border:0 !important;
    opacity: 0;
    visibility: hidden;
    -webkit-appearance: none !important;
    box-shadow: none !important;
}

.def-button {
    opacity: 1;
    visibility: visible;
}

.upload-button {
    margin: 27px auto 15px;
    color: #fff;
    position: relative;
    letter-spacing: 0.1em;
    border: none !important;
    transition: .2s .2s width, .2s .2s height, .4s .2s color, .6s border, .6s background, .2s box-shadow;
    outline: none;
    cursor: pointer;
    width: 81px;
    height: 81px;
    border-radius: 50%;
    background: transparent url(/static/media/upload.49148437.svg) no-repeat center center;
}
.upload-button + span {
    display: block;
    margin: 0 auto;
    color: #1d1d1b;
    font-weight: 700;
}
.upload-button:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #ee721e;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0;
    transition: .3s opacity;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
}
.upload-button:before {
    content: attr(data-progress) "%";
    font-size: 1em;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #ee721e;
    opacity: 0;
    transition: 0s;
}
.upload-button:hover {
    background: #ee721e;
    border-color: #ee721e;
}
.upload-button:active {
    box-shadow: 0 0 0 5px #ffd78e;
}
.upload-button:disabled {
    color: transparent;
    width: 5em;
    height: 5em;
    border: 4px solid #ffd78e;
    background: transparent;
    cursor: default;
    transition: .3s .2s width, .3s .2s height, .2s color, .3s .2s border, .3s .2s background;
}
.upload-button:disabled + span {
    font-weight: 400;
    font-style: italic;
}
.upload-button:disabled:after {
    opacity: 1;
    transition: .6s .3s opacity;
}
.upload-button:disabled:before {
    opacity: 1;
    transition: .6s .3s opacity;
}
.upload-button:disabled.completed {
    background: transparent url(/static/media/upload_success.53e8e8fa.svg) no-repeat center center;

}

.upload-button:disabled.completed:after, .upload-button:disabled.completed:before, .upload-button:disabled.completed + span  {
    opacity: 0;
    transition: .2s;
}

.is-completed .image-container {
    transition: opacity .35s ease;
    opacity: 0;
}
.is-completed textarea {
    transition: opacity .35s ease .2s;
    opacity: 0;
}
.is-completed .text-container {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: -webkit-transform .6s ease .4s;
    transition: transform .6s ease .4s;
    transition: transform .6s ease .4s, -webkit-transform .6s ease .4s;
}
.is-completed .reset-button {
    opacity: 1;
    visibility: visible;
    transition: opacity .4s ease .5s;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0);
                transform: translate(-50%, -50%) rotate(0);
    }
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0);
                transform: translate(-50%, -50%) rotate(0);
    }
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }
}

.image-container {

    max-height: 200px;
    text-align: center;
    margin-right: 28px;
}
.image-container img {
    max-width: 100%;
    max-height: 100%;
}
.desc-container, .edit-container {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(0, -5%);
            transform: translate(0, -5%);
    background: #ddd;
    box-shadow: 10px 10px 50px -2px rgba(0,0,0,0.23);
    border-radius: 3px;
    padding: 45px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: opacity .45s ease, visibility 0s ease .45s, -webkit-transform .45s ease;
    transition: transform .45s ease, opacity .45s ease, visibility 0s ease .45s;
    transition: transform .45s ease, opacity .45s ease, visibility 0s ease .45s, -webkit-transform .45s ease;
}
.desc-container.is-visible,
.edit-container.is-visible {
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
    visibility: visible;
    z-index: 3;
    opacity: 1;
    transition: opacity .45s ease, -webkit-transform .45s ease;
    transition: transform .45s ease, opacity .45s ease;
    transition: transform .45s ease, opacity .45s ease, -webkit-transform .45s ease;
}

.edit-container.is-visible {
    z-index: 4;
}

.text-container {
    width: 50%;
    text-align: center;
    padding: 20px 35px;
}
@media all and (max-width: 720px) {
    .text-container, .image-container {
        width: 100%;
    }
    .image-container {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 27px;
    }
}

textarea {
    width: 100%;
    border: none;
    background: #efefef;
    font-size: 17px;
    color: #6d6d6d;
    height: 100px;
    padding: 10px 15px;
    outline: none !important;
    -webkit-appearance: none;
}
textarea:focus {

}

.main-logo {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 17px;
    left: 0;
}

@media all and (orientation: portrait) {
    .main-logo {
        top: 30px;
    }
}
.main-logo img {
    max-width: 196px;
}

.footer-logo {
    position: absolute;
    z-index: 1;
    max-width: 139px;
    height: auto;
    bottom: 17px;
    left: 17px;
}

#preview {
    max-height: 300px;
}

.cropper-bg {
    background-repeat: repeat;
}

.edit-container > div {
    width: 100%;
}

.cropper-container {
    width: 300px
}

.edit-container .image-container {
    width: 100%;
    height: 0 !important;
    padding-bottom: 60%;
}

.edit-container img {
    display: block;
    width: auto;
    height: 100%;
}

.cropper-point,
.cropper-line {
    background-color: #ee721e;
}

.cropper-view-box {
    outline-color: rgba(238, 114, 30, 0.7);
}

.edit-container {
    text-align: center;
}

.edit-title {
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    text-transform: uppercase;
}

./* CSS reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {
    margin:0;
    padding:0;
}
html,body {
    margin:0;
    padding:0;
}
table {
    border-collapse:collapse;
    border-spacing:0;
}
fieldset,img {
    border:0;
}
input{
    border:1px solid #b0b0b0;
    padding:3px 5px 4px;
    color:#979797;
    width:190px;
}
address,caption,cite,code,dfn,th,var {
    font-style:normal;
    font-weight:normal;
}
ol,ul {
    list-style:none;
}
caption,th {
    text-align:left;
}
h1,h2,h3,h4,h5,h6 {
    font-size:100%;
    font-weight:normal;
}
q:before,q:after {
    content:'';
}
abbr,acronym { border:0;
}

.slider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.9);
}

ul {
    list-style-type: none;
}

.slider {
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    transition: opacity .4s ease, visiblity 0s ease .4s;
}

.slider.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity .8s ease-in-out;
}

.slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .8s ease;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.slider .slide > div {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 0;
    left: 0;
    background: no-repeat center center;
    background-size: cover;
}
.slider .slide.show {
    opacity: 1;
}

.slider .slide > div:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16vh;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}

.slider .slide.portrait > div {
    background-size: auto 100%;
}

.slider .slide > span {
    display: block;
    position: absolute;
    bottom: 3vw;
    left: 2vw;
    color: #fff;
    font-size: 3.5vw;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    max-width: 80vw;
    line-height: 1.2;
}

.slider .clock {
    display: none;
    position: absolute;
    bottom: 3vw;
    right: 2vw;
    line-height: 1;
    color: #fff;
    font-size: 5.5vw;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.slider .clock.show {
    display: block;
}

.toggleSwitch span span {
    display: none;
}

.settings {
    width: 300px;
    margin: 30px auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #2f2f2f;
}

.settings td {
    vertical-align: middle;
}

.settings button {
    margin: 20px auto;
    color: #fff;
    position: relative;
    padding: 10px 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 20px;
    background-color: #ee721e;
    height: 3em;
    border-radius: 1em;
    transition: .2s .2s width, .2s .2s height, .4s .2s color, .6s border, .6s background, .2s box-shadow;
    outline: none;
    cursor: pointer;
    border:0 !important;
}

.settings button:hover {
    background: #b34a00;
}


@media only screen {
    .toggleSwitch {
        display: inline-block;
        height: 18px;
        position: relative;
        overflow: visible;
        padding: 0;
        cursor: pointer;
        width: 50px;
        margin-top: 20px;
        font-family: 'Roboto', sans-serif;
    }
    .toggleSwitch * {
        box-sizing: border-box;
    }
    .toggleSwitch label,
    .toggleSwitch > span {
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
    }
    .toggleSwitch input:focus ~ a,
    .toggleSwitch input:focus + label {
        outline: none;
    }
    .toggleSwitch label {
        position: relative;
        z-index: 3;
        display: block;
        width: 100%;
    }
    .toggleSwitch input {
        position: absolute;
        opacity: 0;
        z-index: 5;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
    }
    .toggleSwitch > span {
        position: absolute;
        left: -50px;
        width: 100%;
        margin: 0;
        padding-right: 50px;
        text-align: left;
        white-space: nowrap;
    }
    .toggleSwitch > span span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        display: block;
        width: 50%;
        margin-left: 50px;
        text-align: left;
        font-size: 0.9em;
        width: 100%;
        left: 15%;
        top: -1px;
        opacity: 0;
    }
    .toggleSwitch a {
        position: absolute;
        right: 50%;
        z-index: 4;
        display: block;
        height: 100%;
        padding: 0;
        left: 2px;
        width: 18px;
        background-color: #fff;
        border: 1px solid #CCC;
        border-radius: 100%;
        transition: all 0.2s ease-out;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }
    .toggleSwitch > span span:first-of-type {
        color: #ccc;
        opacity: 1;
        left: 45%;
    }
    .toggleSwitch > span:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50px;
        top: -2px;
        background-color: #fafafa;
        border: 1px solid #ccc;
        border-radius: 30px;
        transition: all 0.2s ease-out;
    }
    .toggleSwitch input:checked ~ a {
        border-color: #fff;
        left: 100%;
        margin-left: -8px;
    }
    .toggleSwitch input:checked ~ span:before {
        border-color: #ff7200;
        box-shadow: inset 0 0 0 30px #ff7200;
    }
    .toggleSwitch input:checked ~ span span:first-of-type {
        opacity: 0;
    }
    .toggleSwitch input:checked ~ span span:last-of-type {
        opacity: 1;
        color: #fff;
    }
    /* Switch Sizes */
    .toggleSwitch.large {
        width: 60px;
        height: 27px;
    }
    .toggleSwitch.large a {
        width: 27px;
    }
    .toggleSwitch.large > span {
        height: 29px;
        line-height: 28px;
    }
    .toggleSwitch.large input:checked ~ a {
        left: 41px;
    }
    .toggleSwitch.large > span span {
        font-size: 0.9em;
    }
    .toggleSwitch.large > span span:first-of-type {
        left: 50%;
    }
}
html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

