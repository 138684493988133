./* CSS reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {
    margin:0;
    padding:0;
}
html,body {
    margin:0;
    padding:0;
}
table {
    border-collapse:collapse;
    border-spacing:0;
}
fieldset,img {
    border:0;
}
input{
    border:1px solid #b0b0b0;
    padding:3px 5px 4px;
    color:#979797;
    width:190px;
}
address,caption,cite,code,dfn,th,var {
    font-style:normal;
    font-weight:normal;
}
ol,ul {
    list-style:none;
}
caption,th {
    text-align:left;
}
h1,h2,h3,h4,h5,h6 {
    font-size:100%;
    font-weight:normal;
}
q:before,q:after {
    content:'';
}
abbr,acronym { border:0;
}

.slider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.9);
}

ul {
    list-style-type: none;
}

.slider {
    transform: translate3d(0,0,0);
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    transition: opacity .4s ease, visiblity 0s ease .4s;
}

.slider.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity .8s ease-in-out;
}

.slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .8s ease;
    transform: translate3d(0,0,0);
    backface-visibility: hidden;
}

.slider .slide > div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate3d(0,0,0);
    backface-visibility: hidden;
    top: 0;
    left: 0;
    background: no-repeat center center;
    background-size: cover;
}
.slider .slide.show {
    opacity: 1;
}

.slider .slide > div:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16vh;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}

.slider .slide.portrait > div {
    background-size: auto 100%;
}

.slider .slide > span {
    display: block;
    position: absolute;
    bottom: 3vw;
    left: 2vw;
    color: #fff;
    font-size: 3.5vw;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    max-width: 80vw;
    line-height: 1.2;
}

.slider .clock {
    display: none;
    position: absolute;
    bottom: 3vw;
    right: 2vw;
    line-height: 1;
    color: #fff;
    font-size: 5.5vw;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.slider .clock.show {
    display: block;
}

.toggleSwitch span span {
    display: none;
}

.settings {
    width: 300px;
    margin: 30px auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #2f2f2f;
}

.settings td {
    vertical-align: middle;
}

.settings button {
    margin: 20px auto;
    color: #fff;
    position: relative;
    padding: 10px 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 20px;
    background-color: #ee721e;
    height: 3em;
    border-radius: 1em;
    transition: .2s .2s width, .2s .2s height, .4s .2s color, .6s border, .6s background, .2s box-shadow;
    outline: none;
    cursor: pointer;
    border:0 !important;
}

.settings button:hover {
    background: #b34a00;
}


@media only screen {
    .toggleSwitch {
        display: inline-block;
        height: 18px;
        position: relative;
        overflow: visible;
        padding: 0;
        cursor: pointer;
        width: 50px;
        margin-top: 20px;
        font-family: 'Roboto', sans-serif;
    }
    .toggleSwitch * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .toggleSwitch label,
    .toggleSwitch > span {
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
    }
    .toggleSwitch input:focus ~ a,
    .toggleSwitch input:focus + label {
        outline: none;
    }
    .toggleSwitch label {
        position: relative;
        z-index: 3;
        display: block;
        width: 100%;
    }
    .toggleSwitch input {
        position: absolute;
        opacity: 0;
        z-index: 5;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
    }
    .toggleSwitch > span {
        position: absolute;
        left: -50px;
        width: 100%;
        margin: 0;
        padding-right: 50px;
        text-align: left;
        white-space: nowrap;
    }
    .toggleSwitch > span span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        display: block;
        width: 50%;
        margin-left: 50px;
        text-align: left;
        font-size: 0.9em;
        width: 100%;
        left: 15%;
        top: -1px;
        opacity: 0;
    }
    .toggleSwitch a {
        position: absolute;
        right: 50%;
        z-index: 4;
        display: block;
        height: 100%;
        padding: 0;
        left: 2px;
        width: 18px;
        background-color: #fff;
        border: 1px solid #CCC;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }
    .toggleSwitch > span span:first-of-type {
        color: #ccc;
        opacity: 1;
        left: 45%;
    }
    .toggleSwitch > span:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50px;
        top: -2px;
        background-color: #fafafa;
        border: 1px solid #ccc;
        border-radius: 30px;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }
    .toggleSwitch input:checked ~ a {
        border-color: #fff;
        left: 100%;
        margin-left: -8px;
    }
    .toggleSwitch input:checked ~ span:before {
        border-color: #ff7200;
        box-shadow: inset 0 0 0 30px #ff7200;
    }
    .toggleSwitch input:checked ~ span span:first-of-type {
        opacity: 0;
    }
    .toggleSwitch input:checked ~ span span:last-of-type {
        opacity: 1;
        color: #fff;
    }
    /* Switch Sizes */
    .toggleSwitch.large {
        width: 60px;
        height: 27px;
    }
    .toggleSwitch.large a {
        width: 27px;
    }
    .toggleSwitch.large > span {
        height: 29px;
        line-height: 28px;
    }
    .toggleSwitch.large input:checked ~ a {
        left: 41px;
    }
    .toggleSwitch.large > span span {
        font-size: 0.9em;
    }
    .toggleSwitch.large > span span:first-of-type {
        left: 50%;
    }
}